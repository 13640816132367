.row--heading {
    @extend .py-5-5;

    justify-content: center;
}

.row--sitemap {
    @extend .pb-5-5;

    ul {
        list-style-type: none;
    }

    li li {
        @extend .pl-5;
    }
}

.row--pagination {
    align-items: center;

    ol, .pagination {
        @extend .col-12, .col-md-6, .mb-0;
    }

    ol li {
        display: inline-block;
        list-style-type: none;

        &:not(:first-of-type) {
            @extend .ml-2;
        }

        a {
            border-radius: 0;
            background-color: theme-color("grey_3");

            &:hover,
            &.button--current {
                background-color: theme-color("brand_green_1");
            }
        }
    }

    .pagination {
        @extend .mt-3, .mt-md-0;
        justify-content: flex-end;

        span {
            font-size: theme-font-size("smaller_1");
            color: theme-color("grey_1");

            &:not(:first-of-type) {
                @extend .ml-2;
            }

            &.current {
                @extend .py-2, .px-3;
                line-height: normal;
                background-color: theme-color("brand_green_1");
            }

            a {
                @extend .py-2, .px-3;
                display: block;
                line-height: normal;
                color: theme-color("grey_1");
                background-color: theme-color("grey_3");

                &:hover {
                    background-color: theme-color("brand_green_1");
                }
            }
        }
    }

    &--center {
        .pagination {
            @extend .mb-5-5;

            margin: 0 auto;
            justify-content: center !important;
        }
    }

    &~.container--blogs {
        @extend .my-5-5;
    }
}

.row--featured-properties,
.row--blogs,
.row--testimonials {
    @extend .flex-column, .pb-5-5;
}

.row--testimonials {
    background-color: theme-color("grey_3");
}

.row--featured-properties {
    background-color: theme-color("grey_2");
}

.row--cp {
    @extend .py-5-5;

    background-color: theme-color("grey_2");

    .container--cp .row {
        @extend .col-10, .mx-auto;

        justify-content: space-between;

        section {
            @extend .col-12, .col-md-6;

            &:nth-of-type(odd) {
                @extend .pr-0, .pr-md-5;
            }

            &:nth-of-type(even) {
                @extend .pl-0, .pl-md-5;
            }

            &:not(:first-of-type) {
                @extend .mt-5;
            }

            &:nth-of-type(n+3) {
                @extend .mt-md-5;
            }

            &:nth-of-type(1),
            &:nth-of-type(2) {
                @extend .mt-md-0;
            }
        }
    }

    a {
        @extend .d-flex, .pr-5-5, .pl-5-5, .pt-5-5, .pb-7-5;
        height: 245px;
        background-color: theme-color("black");
        position: relative;

        div, img {
            @extend .col-6;
        }

        img {
            width: auto;
            height: auto;
        }

        svg {
            position: absolute;
            bottom: 3rem;
            right: 3rem;
        }
    }
}

.row--testimonials--full {
    .container {
        flex-direction: column;
    }

    .listing--testimonial {
        @extend .col-12, .col-md-12, .mt-5-5, .mt-md-5-5;

        &:nth-child(odd),
        &:nth-child(even) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}

.row--featured-properties {
    flex-direction: row;

    section {
        text-align: center;
    }

    a.button {
        display: inline-block;
    }
}

.container--properties--featured-properties,
.container--blogs {
}

.container--properties {
    &--properties {
        header, article, &>section {
            @extend .col-10, .p-0;
            margin-left: auto !important;
            margin-right: auto !important;
        }

        header {
            @extend .mt-5-5;
            position: relative;

            h1, h2 {
                text-align: center;
            }
        }

        .price {
            font-family: $font-family-avenir-medium;
            color: theme-color("brand_green_1");
            font-size: 2.6rem;
        }

        .listing--property {
            @extend  .row, .pb-5-5, .mt-5-5, .border-bottom, .ml-auto, .mr-auto;

            &>p {
                @extend .px-3, .py-2;
                z-index: 100;

                text-transform: uppercase;
                font-weight: bold;
                position: absolute;
                top: 0;
                left: 0;
                font-size: theme-font-size("smaller_1");

                background-color: theme-color("brand_green_1");
            }

            &>a {
                @extend .col-12, .col-md-5;
            }

            @include media-breakpoint-down(sm) {
                display: block;
            }

            img {

            }

            div {
                @extend .col-12, .col-md-7, .pl-0, .pl-md-5-5, .pt-5, .pt-md-0;
            }

            .row--buttons {
                @extend .mt-3;
                width: 100%;
                justify-content: flex-end;

                @include media-breakpoint-down(sm) {
                    justify-content: flex-start;
                }

                .button {
                    @extend .clearfix, .mx-0;
                    display: block;

                    &:not(:first-of-type) {
                        @extend .ml-3;
                    }
                }
            }
        }
    }

    &--featured-properties {
        display: flex;
        justify-content: space-between;

        @include media-breakpoint-down(sm) {
            display: block;
        }

        h1 {
            @extend .col-12;
        }

        .listing--property, .listing--property-lbu {
            @extend .card, .col-12, .col-md-4, .border-0, .pl-md-3, .pr-md-3, .mt-md-0;
            background-color: theme-color("grey_2");

            color: inherit;

            &:not(:first-child) {
                @extend .pt-5, .pt-md-0;
            }

            @include media-breakpoint-down(sm) {
                display: block;
            }

            &:first-child {
                @extend .pl-md-0, .pr-md-5;
            }

            &:last-child {
                @extend .pr-md-0, .pl-md-5;
            }

            img, div {
            }

            img {
                @extend .card-img-top;
            }

            div {
                @extend .card-body, .px-0;

                text-align: left;
            }

            p {
                @extend .mb-0;
            }
        }
    }
}

.container--blogs {
    h2 {
        @extend .mb-5;
    }

    a {
        @extend .row, .shadow;

        color: inherit;

        div {
            @extend .col-12, .col-md-6;

            display: flex;
            flex-direction: column;
            justify-content: center;

            svg {
                display: block;
                position: relative;
                float: right;
                color: theme-color("brand_green_1");
                font-size: 4rem;
            }

            &:nth-child(even) {
                @extend .p-5;
            }
        }

        &:nth-child(even) {
            flex-direction: row-reverse;
        }

        &:not(:first-of-type) {
            @extend .mt-5-5;
        }

        section {
            @extend .clearfix;
        }
    }
}

.container--testimonials {
    @extend .d-block, .d-md-flex;
    justify-content: space-between;
}

.container + .row--testimonials .container--testimonials {
    @extend .d-block;
}

.listing--testimonial {
    @extend .col-12, .col-md-6;

    &:not(:first-of-type) {
        @extend .mt-5-5, .mt-md-0;
    }

    &:nth-child(odd) {
        padding-right: ($spacer * 2) !important;
    }

    &:nth-child(even) {
        padding-left: ($spacer * 2) !important;
    }

    div {
        @extend .p-5;
        background: white url("/build/img/ui/quotes.png") bottom 20px right 30px no-repeat;

        p:last-of-type {
            margin-bottom: 0;
        }

        .listing-type {
            margin-bottom: 0;
        }
    }

    @include media-breakpoint-down(md) {
        &:nth-child(odd), &:nth-child(even) {
            padding-left: 0 !important;
            padding-right: 0 !important;
        }
    }
}

.listing--blog__date {
    @extend .px-4, .pb-2;
    padding-top: 20px;
    display: block;
    position: absolute;
    top: 0;
    background-color: theme-color("brand_green_1");
    text-align: center;

    &__day {
        @extend h1;
        display: block;
        line-height: 100%;
    }

    &__month {
        top: -5px;
        display: block;
        position: relative;
        font-size: 1.5rem;
        text-transform: uppercase;
        font-family: $font-family-avenir-medium;
    }
}

.listing--blog {
    &:nth-of-type(odd) {
        .listing--blog__date {
            left: 0;
        }
    }

    &:nth-of-type(even) {
        .listing--blog__date {
            right: 0;
        }
    }
}

.title-listing--person {
    @extend .mt-5-5;
}

.listing--person {
    @extend  .row, .flex-column-reverse, .flex-md-row, .pb-5-5, .border-bottom, .ml-auto, .mr-auto, .mt-5-5;

    section {
        @extend .col-4, .col-md-2;

        margin: 0 auto;

        img, a {
            width: 100%;
        }

        img {
            @extend .d-none, .d-md-block;
            border-radius: 50%;
        }

        a {
            @extend .mt-4;
        }

        @include media-breakpoint-down(md) {
            margin: 0;
        }
    }

    div {
        @extend .col-12, .col-md-10, .pl-0, .pl-md-5-5;

        h2 {
            @extend .mb-4;
        }
    }

    .row--buttons {
        @extend .mt-3;
        width: 100%;
        flex-direction: row-reverse;

        .button {
            @extend .clearfix;
            display: block;

            &:not(:first-of-type) {
                @extend .mr-3;
            }
        }
    }
}
