/**
 * @license
 * MyFonts Webfont Build ID 2812463, 2014-05-15T09:27:12-0400
 *
 * The fonts listed in this notice are subject to the End User License
 * Agreement(s) entered into by the website owner. All other parties are
 * explicitly restricted from using the Licensed Webfonts(s).
 *
 * You may obtain a valid license at the URLs below.
 *
 * Webfont: Avenir 65 Medium by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/65-medium/
 * Copyright: Copyright &#x00A9; 1981 - 2006 Linotype GmbH, www.linotype.com. All rights reserved. Copyright &#x00A9; 1989 - 2002 Adobe Systems Incorporated.  All Rights Reserved.
 *
 * Webfont: Avenir 55 Roman by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/55-roman/
 * Copyright: Copyright &#x00A9; 1989, 1995, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1995, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 *
 * Webfont: Avenir 35 Light by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/35-light/
 * Copyright: Copyright &#x00A9; 1989, 1995, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1995, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 *
 * Webfont: Avenir 45 Book by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/45-book/
 * Copyright: Copyright &#x00A9; 1989, 1995, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1995, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 *
 * Webfont: Avenir 95 Black by Linotype
 * URL: http://www.myfonts.com/fonts/linotype/avenir/95-black/
 * Copyright: Copyright &#x00A9; 1989, 1995, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1995, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
 *
 *
 * License: http://www.myfonts.com/viewlicense?type=web&buildid=2812463
 * Licensed pageviews: 250,000
 *
 * © 2014 MyFonts Inc
 */
@font-face {
  font-family: 'AvenirLTStd-Medium';
  src: url("/build/fonts/avenir/2AEA2F_0_0.eot");
  src: url("/build/fonts/avenir/2AEA2F_0_0.eot?#iefix") format("embedded-opentype"), url("/build/fonts/avenir/2AEA2F_0_0.woff") format("woff"), url("/build/fonts/avenir/2AEA2F_0_0.ttf") format("truetype"), url("/build/fonts/avenir/2AEA2F_0_0.svg#wf") format("svg");
  font-display: swap;
}
/*@font-face {
  font-family: 'AvenirLTStd-Roman';
  src: url("/build/fonts/avenir/2AEA2F_1_0.eot");
  src: url("/build/fonts/avenir/2AEA2F_1_0.eot?#iefix") format("embedded-opentype"), url("/build/fonts/avenir/2AEA2F_1_0.woff") format("woff"), url("/build/fonts/avenir/2AEA2F_1_0.ttf") format("truetype"), url("/build/fonts/avenir/2AEA2F_1_0.svg#wf") format("svg"); }*/
@font-face {
  font-family: 'AvenirLTStd-Light';
  src: url("/build/fonts/avenir/2AEA2F_2_0.eot");
  src: url("/build/fonts/avenir/2AEA2F_2_0.eot?#iefix") format("embedded-opentype"), url("/build/fonts/avenir/2AEA2F_2_0.woff") format("woff"), url("/build/fonts/avenir/2AEA2F_2_0.ttf") format("truetype"), url("/build/fonts/avenir/2AEA2F_2_0.svg#wf") format("svg");
  font-display: swap;
}
/*@font-face {
  font-family: 'AvenirLTStd-Book';
  src: url("/build/fonts/avenir/2AEA2F_3_0.eot");
  src: url("/build/fonts/avenir/2AEA2F_3_0.eot?#iefix") format("embedded-opentype"), url("/build/fonts/avenir/2AEA2F_3_0.woff") format("woff"), url("/build/fonts/avenir/2AEA2F_3_0.ttf") format("truetype"), url("/build/fonts/avenir/2AEA2F_3_0.svg#wf") format("svg"); }*/
@font-face {
  font-family: 'AvenirLTStd-Black';
  src: url("/build/fonts/avenir/2AEA2F_4_0.eot");
  src: url("/build/fonts/avenir/2AEA2F_4_0.eot?#iefix") format("embedded-opentype"), url("/build/fonts/avenir/2AEA2F_4_0.woff") format("woff"), url("/build/fonts/avenir/2AEA2F_4_0.ttf") format("truetype"), url("/build/fonts/avenir/2AEA2F_4_0.svg#wf") format("svg");
  font-display: swap;
}
