@import "~bootstrap/scss/bootstrap";

@import 'fonts.scss';


$font-family-avenir-light: 'AvenirLTStd-Light', sans-serif;
$font-family-avenir-medium: 'AvenirLTStd-Medium', sans-serif;
$font-family-avenir-black: 'AvenirLTStd-Black', sans-serif;

$theme-colors: (
    "black": #000000,
    "white": #ffffff,
    "brand_green_1": #A6CE39,
    "grey_1": #3E4543,
    "grey_2": #F2F2F2,
    "grey_3": #E5E6E7
);

$theme-font-sizes: (
    "smallest_1": 1.2rem,
    "smaller_1": 1.5rem,
    "smaller_2": 1.6rem,
    "medium_1": 2.2rem,
    "large": 2.6rem,
    "x-large": 4.8rem
);

@function theme-font-size($key) {
    @if map-has-key($theme-font-sizes, $key) {
        @return map-get($theme-font-sizes, $key);
    }

    @warn "Unknown `#{$key}` in $theme-font-sizes.";
    @return null;
}

%transition-10 {
    transition: all 0.1s ease-in-out;
    -webkit-transition: all 0.10s ease-in-out;
    -moz-transition: all 0.10s ease-in-out;
    -ms-transition: all 0.10s ease-in-out;
    -o-transition: all 0.10s ease-in-out;
}

%transition-20 {
    transition: all 0.2s ease-in-out;
    -webkit-transition: all 0.20s ease-in-out;
    -moz-transition: all 0.20s ease-in-out;
    -ms-transition: all 0.20s ease-in-out;
    -o-transition: all 0.20s ease-in-out;
}

%transition-30 {
    transition: all 0.3s ease-in-out;
    -webkit-transition: all 0.30s ease-in-out;
    -moz-transition: all 0.30s ease-in-out;
    -ms-transition: all 0.30s ease-in-out;
    -o-transition: all 0.30s ease-in-out;
}

html, body {
    font-family: $font-family-avenir-light;
}

html {
    font-size: 50%;
    overflow-x: hidden;

    @include media-breakpoint-up(lg) {
        font-size: 62.5%;
    }
}


body {
    font-size: theme-font-size("medium_1");
    color: theme-color("black");
}

a {
    @extend %transition-20;

    color: theme-color("brand_green_1");

    &, &:hover, &:focus, &:active {
        text-decoration: none;

        color: theme-color("brand_green_1");
    }
}

a, button, select {
    &:focus, &:active {
        outline: none !important;
        box-shadow: none;
    }
}

h1, h2, h3, h4, ul {
    margin: 0;
    padding: 0;
}

h1, .h1 {
    font-family: $font-family-avenir-black;
    font-size: 4rem;
}

h2 {
    font-family: $font-family-avenir-medium;
    font-size: 3rem;
}

h3 {
    font-family: $font-family-avenir-medium;
    color: theme-color("brand_green_1");
    font-size: 2.6rem;
}

h4 {
    font-size: 100%;
    font-family: $font-family-avenir-black;
}


img {
    object-fit: contain;
    width: 100%;
    height: auto;
}

label {
    margin-bottom: 0;
}

strong {
    font-family: $font-family-avenir-medium;
}

.smaller {
    font-size: 1.6rem;
}

.ck-content {
    margin: 0 auto;
}

form input, form textarea {
    font-size: 1.6rem;
}

#content-container>.container,
.row>.container {
    @extend .pl-5, .pl-md-0, .pr-5, .pr-md-0;
}

.container,
.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {
    @extend .p-0;
}

.py-5-5 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
}

.py-10 {
    padding-top: 10rem !important;
    padding-bottom: 10rem !important;
}

.px-10 {
    padding-left: 10rem !important;
    padding-right: 10rem !important;
}

.pt-20 {
    padding-top: 20rem !important;
}

.pb-10 {
    padding-bottom: 10rem !important;
}

.pt-5-5 {
    padding-top: 5rem !important;
}

.mt-10 {
    margin-top: 10rem !important;
}

.mt-20 {
    margin-top: 20rem !important;
}

.mb-10 {
    margin-bottom: 10rem !important;
}

.pl-5-5 {
    padding-left: 5rem !important;
}

.pl-md-5-5 {
    @include media-breakpoint-up(md) {
        padding-left: 5rem !important;
    }
}

.pr-md-5-5 {
    @include media-breakpoint-up(md) {
        padding-right: 5rem !important;
    }
}

.p-5-5 {
    padding: 5rem !important;
}

.pb-5-5 {
    padding-bottom: 5rem !important;
}

.pr-5-5 {
    padding-right: 5rem !important;
}

.pb-7-5 {
    padding-bottom: 7.5rem !important;
}

.pt-5-5 {
    padding-top: 5rem !important;
}

.my-5-5 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
}

.mr-5-5 {
    margin-right: 5rem !important;
}

.mt-5-5 {
    margin-top: 5rem !important;
}

.mt-md-0 {
    @include media-breakpoint-up(md) {
        margin-top: 0 !important;
    }
}

.mt-md-5-5 {
    @include media-breakpoint-up(md) {
        margin-top: 5rem !important;
    }
}

.mb-5-5 {
    margin-bottom: 5rem !important;
}

.row {
    @extend .ml-0, .mr-0;
}

.cc_button,
.button,
input[type="submit"] {
    display: block;
    text-align: center;
    border: none;
    margin: 0 auto;
    border-radius: 1.5rem;
    font-size: 1.5rem;
    background-color: theme-color("brand_green_1");
    font-family: $font-family-avenir-medium;
    text-transform: uppercase;
    padding: .5rem 2rem;

    &, &:hover, &:focus, &:active {
        color: theme-color("black");
    }
}

@import '../simpl/helpers.scss';
@import 'header.scss';
@import 'content.scss';
@import 'list.scss';
@import 'property.scss';
@import 'www_default.scss';
@import 'newsletter.scss';
@import 'footer.scss';
