#header {
    @extend .py-3;
    background-color: theme-color("black");
}

/*
    NAV
*/
.navbar {
    @extend .py-0, .container, .px-5, .px-lg-0;
}

.navbar-brand img {
    width: 150px;
}

.navbar-dark .navbar-nav .nav-link {
    color: theme-color("white") !important;
    font-size: theme-font-size("smaller_1");
    text-transform: uppercase;

    &:hover {
        color: theme-color("brand_green_1") !important;
    }
}

.navbar-dark .navbar-nav.navbar-nav--secondary {
    @extend .mb-3;
}

.navbar-dark .navbar-nav.navbar-nav--secondary .nav-link {
    @extend .px-4;
    
    font-size: theme-font-size("smallest_1");
    border: 1px solid theme-color("brand_green_1");
    border-radius: 1.5rem;
}


.navbar-dark .navbar-nav .nav-item {
    &:not(:first-child) {
        @extend .ml-0, .ml-md-3;
    }

    .button {
        @extend .d-inline-block, .d-lg-block, .mt-3, .mt-lg-0;
    }
}

.navbar-dark .navbar-toggler {
    border: none !important;
}

.navbar-dark .navbar-toggler-icon {
    display: block;
    width: auto;
    height: auto;
    background-image: none !important;
}

.navbar-nav {
    @extend .w-100;

    justify-content: flex-end !important;
}

.navbar-collapse.navbar-collapse--subnav {
    @extend .mt-3, .mt-lg-0, .border-0;

    flex-direction: column-reverse;
    align-self: flex-end;
}

.navbar-expand-lg .navbar-nav .dropdown-menu a.nav-link {
    @extend .py-lg-2, .py-lg-4, .px-5;
}

ul.dropdown-menu {
    @extend .py-0, .mt-0, .border-0;

    background-color: theme-color("black");
}

.dropdown-item {
    &.active, &:hover, &:focus {
        color: theme-color("brand_green_1");
        text-decoration: none;
        background-color: theme-color("black");
    }
}

.dropdown-toggle::after {
    display: inline-block;
    padding-left: 5px;
    font-size: 10px;
    font-family: "Font Awesome 5 Free";
    font-weight: 900;
    content: "\f078";
    border: 0
}
