@mixin downloadable {
    @extend .p-5;

    li {
        &:not(:first-of-type) {
            @extend .mt-4;
        }

		a {
			display: block;
            min-height: 5.5rem;
			background: transparent url("/build/img/ui/download.svg") top left no-repeat;
            background-size: auto 5.5rem;
			text-decoration: none;
            color: theme-color("grey_1");
            font-weight: 700;
            font-family: $font-family-avenir-medium;
            padding-left: 6rem;
		}

        .ci-repository__filesize {
            display: block;
            font-family: $font-family-avenir-light;
            font-weight: 300;
        }
	}
}

.cc_grey_bg {
    @extend .p-5;

    background-color: theme-color("grey_2");
}

#content {
    h1 {
        @extend .p-0;

        margin: 0 auto;
        text-align: center;
    }



    &>.row {
        @extend .pt-5-5, .pb-5-5;
        background-color: theme-color("grey_2");

        &:not(:first-child) {
            @extend .mt-5-5;
        }

        h2 {
            @extend .mb-5;

            text-align: center;
        }

        .container>* {
            margin: 0 auto;
        }

        &.row--banner {
            @extend .py-0;
            color: theme-color("white");

            -webkit-background-size: cover;
        	-moz-background-size: cover;
        	-o-background-size: cover;
        	background-position: center center;

            h1 {
                @extend .col-12, .col-md-8;
            }
        }
    }

    a {
        text-decoration: underline;
    }

    a.cc_button {
        display: inline-block !important;
        text-decoration: none;
    }

    &>.container {
        @extend .mt-5-5, .mb-5-5;

        .row {
            &>* {
                @extend .col-10;
                margin: 0 auto;

                &:not(:first-child) {
                    @extend .mt-5;
                }
            }

            .ci-text>*:not(:first-child) {
                @extend .mt-5, .mb-0;
            }
        }

        .cc_intro {
            font-size: 3rem;
            text-align: center;
        }

        .ci-image-text {
            display: flex;
            align-items: flex-start;

            img, div {
                @extend .col-6, .pr-4;

                &~div, &~img {
                    @extend .pl-4, .pr-0;
                }
            }

            div {
                &>*:not(:first-child) {
                    @extend .mt-5;
                }
            }
        }
    }

    .ci-repository ul,
    ul.ci-gallery {
        @extend .list-unstyled;
    }

    ul.ci-gallery {
        @extend .d-flex, .mt-n5;
        flex-wrap: wrap;

        li {
            @extend .m-0, .d-block, .col-6, .col-md-4, .col-lg-3, .px-4, .mt-5;

            a, img {
                @extend .d-block;
            }
        }
    }

    .ci-repository {
        @include downloadable;

        &:not(:last-child) {
            @extend .mb-5;
        }

        li {
            @extend .p-4;

            position: relative;
            background-color: theme-color("white");
        }

        svg {
            position: absolute;
            right: 1.5rem;
            bottom: 1.5rem;
        }
    }


    figure {
		img {
			display: block;
			max-width: 100%;
			margin: 0;
		}

        figcaption {
            font-style: italic;
        }
    }

	.ci-embed {
        display: block;
		max-width: 100%;
		width: 560px;
        margin: 0 auto;

		iframe {
			max-width: 100%;
		}

		p {
			display: none;
		}
	}

    p, ul, figure {
    }

    ul {
        list-style-position: outside;
        margin-left: 4rem;

        li {
            &:not(:first-of-type) {
                @extend .mt-2;
            }
        }
    }

    h1, h2, p, ul, figure {
        &:first-child {
            @extend .mt-0;
        }

        &:last-child {
            @extend .mb-0;
        }
    }

    h2 {
        @extend .mb-3, .mt-4;
    }

    h1 {
        @extend .mb-5;
    }

    b, strong {
        font-weight: 700;
    }

    .accordion {
        .card, .card-header {
            border-radius: 0 !important;
            border: none !important;
        }

        .card:not(:first-of-type) {
            @extend .mt-2;
        }

        .card-header {
            @extend .p-0;
            background-color: theme-color("grey_1");

            button {
                @extend .py-2, .pl-4, .pr-5, .text-left;
                position: relative;
                display: block;
                width: 100%;
                color: theme-color("white");
                border: none;
                font-size: 2.2rem;
                font-family: $font-family-avenir-light;
                font-weight: 300;
                &:hover {
                    text-decoration: none;
                }

                &:focus {
                    box-shadow: none;
                }

                svg.fa-chevron-right {
                    @extend %transition-20;
                    position: absolute;
                    right: 20px;
                    top: 24%;
                }

                &[aria-expanded='true'] svg.fa-chevron-right {
                    transform: rotate(90deg);
                }
            }

            h4 {
                @extend .mb-0;
            }
        }

        .card-body {
            border-top: none;
            border-left: 1px solid theme-color("grey_2");
            border-right: 1px solid theme-color("grey_2");
            border-bottom: 1px solid theme-color("grey_2");
        }
    }
}

.container--article-head {
    h1 {
        @extend .mt-5-5, .mb-5;

        text-align: center;
    }

    header {
        @extend .row, .col-10, .mx-auto, .py-5, .my-5, .border-bottom, .border-top;

        align-items: center;

        h3 {
            @extend .col-10, .pl-3;
            color: theme-color("black");
        }

        .img-container--author {
            @extend .col-2;

            img {
                width: 100%;
                max-width: 140px;
                height: auto;
                border-radius: 50%;
            }
        }
    }
}

.ci-embed {
    iframe {
        display: block;
        margin: 0 auto;
    }
}

.row--banner {
    .container {
        @extend .mb-10, .mt-20;
    }
}


.ci-embed {
    .fsBody, form.fsForm {
        @extend .my-0;
    }
}

.form-property {
    @extend .d-none, .d-lg-block, .col-10, .py-3, .pl-5, .pr-3;

    margin: 0 auto;
    background-color: theme-color("grey_2");
    border: 1px solid theme-color("black");
    border-radius: 10px;

    .row {
        align-items: center;
        justify-content: space-evenly;
    }

    .button {
        align-self: end;
    }
}

#content .container .container--view-properties {
    @extend .d-block, .d-lg-none, .py-3;

    position: relative;
    z-index: 1000;

    text-align: center;
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    a.button {
        display: inline-block;
        text-decoration: none;
    }
}

.form-property-block {
    @extend .col-2, .p-0;

    font-size: 1.5rem;

    label {
        display: none;
    }

    select {
        width: 100%;
        color: theme-color("black");
        background: url("/build/img/ui/select-bg.svg") right no-repeat;
        text-transform: uppercase;
        cursor: pointer;
        border: 0 !important;
        border-radius: 0;
        -webkit-appearance: none;
        -moz-appearance: none;
    }

}


.banner_h1 {
    font-size: 6.5rem;
}

.row--form form.container div.form-row {
    @extend .pt-0, .mt-4;
}
