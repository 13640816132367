footer {
    font-size: theme-font-size("smaller_2");
    color: theme-color("grey_2");
}

.row--links-contact,
.row--accreditation {
    .container {
        display: flex;
    }

    h4 {
        @extend .mb-3;
        font-family: $font-family-avenir-medium;
    }

    address {
        display: inline;
    }
}

.row--links-contact {
    @extend .flex-column, .flex-md-row, .py-5-5;

    .container {
        @extend .flex-column, .flex-md-row;

        text-align: center;

        @include media-breakpoint-up(md) {
            text-align: left;
        }
    }
}

.row--accreditation {
    .container {
        @extend .px-10, .py-5-5;

        border-top: 1px solid theme-color("grey_2");
        justify-content: space-evenly;
    }

    a {
        @extend .col-2;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}

.row--links-contact,
.row--copywrite {
    background-color: theme-color("black") !important;
}

.row--links-contact__nav-address {
    @extend .col-12, .col-md-6;

    nav {
        @extend .mb-3;
    }
}

.row--links-contact__social,
.row--links-contact__phone {
    @extend .mt-5, .mt-md-0, .col-12, .col-md-3;
}

.row--links-contact__social,
.row--links-contact__nav-address {
    nav a:not(:first-of-type) {
        @extend .pl-3;
    }
}

.row--copywrite {
    text-align: center;
    font-size: theme-font-size("smallest_1");

    p {
        width: 100%;
    }
}

.row--links-contact__social,
.row--links-contact__phone {
    text-align: right;
}

.row--links-contact__social {
    img {
        width: 43px;
        height: 43px;
    }
}

.row--links-contact__phone {
    p {
        @extend h3;
    }
}
