.overlay {
    position: absolute;
}

.overlay--top {
    top: 0;
}

.overlay--right {
    right: 0;
}

.overlay--left {
    left: 0;
}

.overlay--vc {
    top: 50%;
    transform: translateY(-50%);
}

.row {
    &--banner {
        position: relative;
    }
}

.img-filter-alpha-30,
.img-filter-alpha-50 {
	width: 100%;
	height: 100%;
	background-color: theme-color("grey_1");
}

.img-filter-alpha-30 {
    filter: alpha(opacity=30);
    opacity: 0.3;
}

.img-filter-alpha-50 {
    filter: alpha(opacity=50);
    opacity: 0.5;
}
