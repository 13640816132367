.row-map {
    //@extend .mt-5-5;
    height: 400px;
}

.row--banner.row--banner--properties {
    @extend .d-none, .d-md-flex;
}

.row--banner.row--banner--properties .container {
    @extend .mb-5-5, .mt-5-5;
}

.tab {
    display: none;
}

.tab-active {
    display: block;
}

.nav-tabs a.button {
    display: inline-block;
    text-transform: none;
    margin: 0 1rem 1rem 0;
    border-radius: 0px;
}

.nav-tabs .nav-tab-active {
    background: #2e292b;
    color: #fff;
}

.map,
.street-view,
.video div {
    position: relative;
    height: 0;
    overflow: hidden;
    padding: 30px 0 56.25%;
}

.map embed,
.map iframe,
.map object,
.street-view embed,
.street-view iframe,
.street-view object,
.video div embed,
.video div iframe,
.video div object {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.map img,
.street-view img {
    max-width: none;
}

.map label,
.street-view label {
    width: auto;
    display: inline;
}

.gallery {
    position: relative;
}

.gallery img {
    width: 100%;
    height: auto;
    display: block;
}

.gallery p:first-child {
    position: absolute;
    background-color: #ffffff;
    color: #3B5998;
    padding: 4px;
    font-weight: 700;
    font-size: 0.9em;
    margin: 5px 0 0 5px;
    text-transform: uppercase;
    border-left: 4px solid #a6ce39;
}

.scroller {
    position: relative;
    width: 88%;
    overflow: hidden;
    margin: 10px auto 0;
    left: 0;
}

.gallery-button {
    filter: alpha(opacity=25);
    -ms-filter:  "progid:DXImageTransform.Microsoft.Alpha(Opacity=25)";
    -moz-opacity: 0.25;
    -khtml-opacity: 0.25;
    opacity: 0.25;
    height: 70px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 35%;
    width: 50px;
    background: #a6ce39 url("/build/img/ui/arrow-property-scroller-next.png") center no-repeat;
}

.gallery-button:hover {
    filter: alpha(opacity=100);
    -ms-filter:  "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
    -moz-opacity: 1;
    -khtml-opacity: 1;
    opacity: 1;
}

.gallery-button-previous {
    left: 0;
    right: auto;
    background: #a6ce39 url("/build/img/ui/arrow-property-scroller-previous.png") center no-repeat;
}

.gallery-thumbs {
    margin: 0;
    padding: 0;
    list-style: none;
    position: absolute;
    left: 0;
}

.gallery-thumbs a {
}

.gallery-thumbs li {
    list-style: none;
    display: block;
    float: left;
    position: relative;
    left: 0;
}

.gallery-thumbs li img {
    width: 100%;
    height: auto;
    object-fit: none;
}

.gallery-scroller-button {
    cursor: pointer;
    position: absolute;
    right: 0;
    bottom: 0;
    width: 6%;
    background: #2e292b url("/build/img/ui/arrow-property-scroller-next.png") center no-repeat;
}

.gallery-scroller-button-previous {
    left: 0;
    right: auto;
    background: #2e292b url("/build/img/ui/arrow-property-scroller-previous.png") center no-repeat;
}

.container--property {
    @extend .container--properties--properties;
}

section.container--property {
    header {
        @extend .pb-3, .mb-5-5, .border-bottom;

        h1 {
            @extend .pb-5, .mb-3, .border-bottom;
        }

        h3 {
            &:not(:last-of-type) {
                color: theme-color("black");
            }

            &:last-of-type {
                @include media-breakpoint-up(md) {
                    position: absolute;
                    bottom: 1.5rem;
                    right: 0;
                }
            }
        }
    }

    &__glance, &__description {
        @extend .my-5-5;
    }

    &__glance {
        @extend .border, .p-5, .mt-5-5;

        ul {
            list-style-type: none;
        }
    }
}

.row--property--interested {
    @extend .mt-5-5;

    background-color: theme-color("grey_2");

    .container--property--interested {
        @extend .py-5-5;

        .container--property--interested__content {
            @extend .col-10, .col-md-4;

            margin-left: auto;
            margin-right: auto;
        }
    }

    .button {
        @extend .mt-3;
        width: auto;

        &:first-of-type {
            @extend .mt-5;
        }
    }
}
