.row--form {
    @extend .flex-column, .pb-5-5;

    background-color: theme-color('grey_2');
    .row--heading {
        @extend .pb-3;
    }
}

.form-row {
    @extend .col-12, .col-md-8, .mx-auto, .pt-3;

    &>div, input[type="text"], input[type="email"] {
        width: 100%;
    }

    &>div.choice {
        @extend .d-block, .d-md-inline-block, .ml-0, .ml-lg-5, .mt-2, .mt-lg-0;

        width: auto;
    }

    label {
        display: none;
    }

    textarea {
        display: block;
    }

    textarea,
    select {
        width: 100%;
    }

    select,
    textarea,
    input[type="text"],
    input[type="email"] {
        @extend .px-3, .py-2;

        border: 1px solid theme-color('grey_3');
    }

    select {
        @extend .pr-5;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        background-image: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='%23444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>");
        background-position: 100% 50%;
        background-repeat: no-repeat;
        -webkit-appearance: none;
        -webkit-border-radius: 0px;
    }

    &--checkbox {
        @extend .flex-column, .flex-lg-row;
        justify-content: center;

        label {
            display: block;
        }
    }

    input[type="submit"] {
        @extend .mt-5;
    }
}

.contact-map {
    height: 50vh;
}

.row--form.row--form--lp {
    .container {
        @extend .pl-0, .pr-0;

        margin: 0 auto;
    }

    .form-row {
        @extend .col-10, .col-md-10, .mx-auto, .pt-3;

        &--checkbox {
            justify-content: left;

            .choice {
                @extend .ml-0;
            }
        }
    }

    .row.row--heading {
        @extend .col-10, .col-md-10, .mx-auto, .pt-5-5, .pb-0;

        p {
            @extend .mb-0;
        }
    }
}

.form-sort {
    @extend .mt-5;
    text-align: left;

    input {
        position: relative;
        transform: translateY(-25%);
    }

    &.form-sort--testimonials {
        @extend .mt-n5, .mb-5;
    }
}

/* Custom checkbox */
/* Customize the label (the container) */
.choice {
    position: relative;

    &::before {
        content: "";
        display: table;
        clear: both;
    }

    .form-check {
        padding-left: 0;
    }

    label {
        display: block;
        position: relative;
        padding-left: 35px;
        width: auto;
        cursor: pointer;
        z-index: 2;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        margin: 0;
        padding: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 50%;
        left: 0;
        height: 25px;
        width: 25px;
        transform: translateY(-50%);
        background-color: theme-color('white');

        &:after {
            content: "";
            display: none;
            position: absolute;
            left: 9px;
            top: 5px;
            width: 8px;
            height: 12px;
            border: solid white;
            border-width: 0 3px 3px 0;
            -webkit-transform: rotate(45deg);
            -ms-transform: rotate(45deg);
            transform: rotate(45deg);
        }
    }

    &--testimonials {
        .checkmark {
            background-color: theme-color('grey_3');
        }
    }

    /* On mouse-over, add a grey background color */
    &:hover input ~ .checkmark,
    &--testimonials:hover input ~ .checkmark {
        background-color: theme-color('brand_green_1');
    }

    /* When the checkbox is checked, add a blue background */
    input:checked {
        ~ .checkmark {
            background-color: theme-color('brand_green_1');

            &:after {
                display: block;
            }
        }
    }
}
